
import Vue from "vue";
import UsersPanel from "@/components/Mentoree/UsersPanel.vue";
import AdminPanel from "@/components/AdminPanel.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "AdminPanelPage",
  components: {
    UsersPanel,
    AdminPanel
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      mentoredBy: "profile/getMentoredBy"
    })
  },
  mounted(): void {
    this.$store.dispatch("profile/GET_PROFILE").then(data => {
      if (data.type == "mentee") {
        this.$store.dispatch("profile/GET_MENTORED_BY");
      }
    });
  }
});
